export const getSettingsData = () => ({
    method: "get",
    url: 'setting'
})

export const updateSettingsDataRequest = (data) => ({
    method: "post",
    url: 'setting/update',
    config: {
        data
    }
})
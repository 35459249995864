<template>
  <div class="dashboard">
    <DefaultLayout>
      <PageTitle title="SETTING.TITLE"> </PageTitle>
      <!-- <div class="tabs">
        <div
          class="info"
          :class="tabType == 'account-info' && 'active'"
          @click="setTabType('account-info')"
        >
          {{ $t("SETTING.ACCOUNT_INFO") }}
        </div>
        <div
          class="password"
          :class="tabType == 'change-password' && 'active'"
          @click="setTabType('change-password')"
        >
          {{ $t("SETTING.CHANGE_PASSWORD") }}
        </div>
      </div> -->
      <div class="content-container">
        <h3 class="form-title">{{ $t("SETTING.INCOME") }}</h3>
        <FormValidation @handleSubmit="onSubmitFirst">
          <b-form>
            <div class="info-form">
              <b-row>
                <b-col>
                  <b-row>
                    <b-col>
                      <b-form-group>
                        <TextField
                          v-model="formValues.wadiily"
                          :label="$t('SETTING.WADIILY_INCOME')"
                          :name="$t('SETTING.WADIILY_INCOME')"
                          :placeholder="$t('SETTING.WADIILY_INCOME')"
                          rules="required"
                          @keyup="calcIncome($event)"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group>
                        <TextField
                          v-model="formValues.driver"
                          :label="$t('SETTING.DRIVER_INCOME')"
                          :name="$t('SETTING.DRIVER_INCOME')"
                          :placeholder="$t('SETTING.DRIVER_INCOME')"
                          rules="required"
                          readonly
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-form-group>
                <Button
                  type="submit"
                  color="primary"
                  :d-block="true"
                  :loading="loading"
                  custom-class="mt-2 text-uppercase"
                >
                  {{ $t("GLOBAL.SAVE_CHANGES") }}
                </Button>
              </b-form-group>
            </div>
          </b-form>
        </FormValidation>
      </div>
    </DefaultLayout>
  </div>
</template>

<script>
import PageTitle from "@/components/Shared/PageTitle/index.vue"
import { TogglePasswordMixins } from "@/mixins/TogglePasswordMixins"
import {getSettingsData, updateSettingsDataRequest} from "@/api/settings.js"
import { mapActions } from "vuex"

export default {
  components: {
    PageTitle
  },
  mixins: [TogglePasswordMixins],
  data() {
    return {
      profile: {},
      loading: false,
      tabType: "account-info",
      formValues: {
        wadiily: "",
        driver: ""
      },
    }
  },
  mounted() {
    this.getSettings()
  },
  methods: {
    ...mapActions(["ShowToast"]),
    setTabType(tabValue) {
      this.tabType = tabValue
    },
    calcIncome(e){
      if(this.formValues.wadiily != ''){
        this.formValues.driver = 100-parseInt(this.formValues.wadiily)
      }else{
        this.formValues.driver = ''
      }
    },
    getSettings() {
      this.loading = true
      this.ApiService(getSettingsData())
        .then((res) => {
          if (res.data.status != "success") {
            this.ShowToast({
              title: this.$t("GLOBAL.GLOBAL_FAILED"),
              type: "danger",
              message: res.data.message || this.$t("GLOBAL.GLOBAL_FAILED")
            })
            return res
          }
          if(res.data.data != null){
            this.formValues = res.data.data
          }else{
            this.formValues.wadiily = 100
            this.formValues.driver = 0
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    onSubmitFirst() {
      this.loading = true
      this.ApiService(updateSettingsDataRequest(this.formValues))
      .then((res) => {
        if (res.data.status == "success") {
            this.ShowToast({
              title: this.$t("GLOBAL.PROFILE_UPDATED_SUCCESS"),
              type: "success",
              message: res.data.message || this.$t("GLOBAL.SETTINGS_UPDATED_SUCCESS")
            })
            return res
        }else{
          this.ShowToast({
              title: this.$t("GLOBAL.GLOBAL_FAILED"),
              type: "danger",
              message: res.data.message || this.$t("GLOBAL.GLOBAL_FAILED")
            })
            return res
        }
      })
      .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
